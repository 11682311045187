import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import slugify from "slugify"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

const CardNewsEvent = ({ data }) => {
  const intl = useIntl()
  // const slug = slugify(props.data.title,{lower:true})

  return (
    <div className="post card-container col-lg-4 col-md-12 col-sm-12">
      <div className="blog-post blog-grid blog-rounded blog-effect1">
        <div className="dlab-post-media dlab-img-effect">
          <a href={data.slug}>
            <GatsbyImage image={data.images.gatsbyImageData} alt="" />
          </a>
        </div>
        <div className="dlab-info p-a20 border-1">
          <div className="dlab-post-meta">
            <ul>
              <li className="post-date">
                {" "}
                <strong>{data.newsEventsDate}</strong>
              </li>
            </ul>
          </div>
          <div className="dlab-post-title">
            <h4 className="post-title">{data.title}</h4>
          </div>
          <div className="dlab-post-text">
            <p>{data.descSingkat}</p>
          </div>
          <div className="dlab-post-readmore">
            <a
              href={data.slug}
              title="READ MORE"
              rel="bookmark"
              className="site-button"
            >
              {intl.formatMessage({ id: "newsandevents.cardbtn" })}
              <i className="ti-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardNewsEvent
