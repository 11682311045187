import React from "react"
import { Link } from "gatsby"
import { Accordion, AccordionItem } from "react-sanfona"

import "../../css/custom-css/custom-sidebar.css"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

const SidebarCustom = ({ handleInputChange }) => {
  const intl = useIntl()
  const locale = intl.locale !== "en" ? `/${intl.locale}` : ""

  return (
    <div className="col-lg-3">
      <aside className="side-bar sticky-top">
        <div className="widget">
          <h5 className="widget-title style-1">
            {intl.formatMessage({ id: "newsandevents.search" })}
          </h5>
          <div className="search-bx style-1">
            <form role="search" method="post">
              <div className="input-group">
                <input
                  name="text"
                  className="form-control"
                  placeholder={intl.formatMessage({
                    id: "newsandevents.search",
                  })}
                  type="text"
                  onChange={handleInputChange}
                />
                <span className="input-group-btn">
                  <div className="fa-custom fa fa-search "></div>
                </span>
              </div>
            </form>
          </div>
        </div>
        <div className="widget widget_archive">
          <h5 className="widget-title style-1">
            {intl.formatMessage({ id: "newsandevents.categorieslist" })}
          </h5>
          <ul>
            <li title="News">
              <Link to={"../news"}>
                {intl.formatMessage({ id: "newsandevents.news" })}
              </Link>
            </li>
            <li title="Event">
              <Link to={"../event"}>
                {intl.formatMessage({ id: "newsandevents.events" })}
              </Link>
            </li>
          </ul>
        </div>
      </aside>
    </div>
  )
}

export default SidebarCustom
