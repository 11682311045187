import React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Header from "../layouts/header"
import Footer from "../components/custom-components/FooterCream"
import Sidebar from "../components/custom-components/sidebar-custom"

import "../css/custom-css/custom-news.css"

import bg from "../images/news-event/news-event-banner.webp"
import FloatingBtn from "../components/custom-components/floatingBtn"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

const NewsEventTemplate = ({ children, handleInputChange }) => {
  const intl = useIntl()
  const locale = intl.locale !== "en" ? `/${intl.locale}` : ""

  return (
    <>
      <Header newsEventsPage />
      <FloatingBtn />
      <div className="page-content bg-white">
        <div
          className="dlab-bnr-inr overlay-black-middle bg-pt"
          style={{ backgroundImage: "url(" + bg + ")" }}
        >
          <div className="container">
            <div className="dlab-bnr-inr-entry">
              <h1 className="text-white">
                {intl.formatMessage({ id: "header.newsandevents" })}
              </h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    <Link to={`${locale}/`}>
                      {intl.formatMessage({ id: "header.home" })}
                    </Link>
                  </li>
                  <li>{intl.formatMessage({ id: "header.newsandevents" })}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="content-area">
          <div className="container containerCustom">
            {/* <a href="/"><button className="site-button white m-b50" type="button"><span className="site-button-inr"><i className="fa fa-arrow-left"></i>Back</span></button></a> */}

            <div className="row">
              <div className="col-lg-9 col-md-7 flexCustom">
                <div id="masonry" className="dlab-blog-grid-2">
                  {children}
                </div>
              </div>

              <Sidebar handleInputChange={handleInputChange} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default NewsEventTemplate
